import axios from 'axios';
import { useCallback } from 'react';

const responseInterceptors = [];

const api = axios.create({
  baseURL: process.env.API_URL,
});

api.defaults.headers.common['App-Release'] = process.env.RELEASE?.substr(0, 7);

function setOrganizationHeaders(organizationId) {
  api.defaults.headers.common['x-onomondo-org-id'] = organizationId;
}

function setAuthenticationHeaders(token) {
  api.defaults.headers.common.authorization = token;
}

function unsetAuthenticationHeaders() {
  api.defaults.headers.common.authorization = undefined;
  api.defaults.headers.common['x-onomondo-org-id'] = undefined;
}

function setInterceptors(history, getToken) {
  api.interceptors.request.use(async config => {
    config.headers.authorization = await getToken({ template: process.env.CLERK_DEFAULT_TOKEN_TEMPLATE });
    return config;
  });
  responseInterceptors.push(
    api.interceptors.response.use(null, function (error) {
      const isUnauthorized = error.response && error.response.status === 401;
      if (isUnauthorized) {
        history.push('/logout');
      }
      return Promise.reject(error);
    }),
  );
}

function unsetInterceptors() {
  for (const interceptor of responseInterceptors) {
    api.interceptors.response.eject(interceptor);
  }
}

function useConfig(organizationId) {
  return useCallback(
    (config = {}) => {
      const newConfig = { ...config };
      if (organizationId) {
        newConfig.headers = {
          organization_id: organizationId,
          ...(newConfig.headers || {}),
        };
      }
      return newConfig;
    },
    [organizationId],
  );
}

export {
  setAuthenticationHeaders,
  setOrganizationHeaders,
  unsetAuthenticationHeaders,
  setInterceptors,
  unsetInterceptors,
  useConfig,
};

export default api;
