// import { darken, lighten } from 'polished'
// const osPrefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
// const userPrefersDarkMode = localStorage.getItem('PREFERS_DARK_MODE')
// const prefersDarkMode = osPrefersDarkMode || userPrefersDarkMode
import colors from './colors';

const COPPER_DARKEST = '#573E2F';
const COPPER_DARK = '#8B654F';
const COPPER = '#AB7D63';
const COPPER_LIGHT = '#C38F72';
const COPPER_LIGHTEST = '#E5C3B4';

const DARKEST = '#000000';
const DARKER = '#191919';
const DARK = '#474747';
const DARKMEDIUM = '#757575';

const LIGHTMEDIUM = '#A3A3A3';
const LIGHT = '#D1D1D1';
const LIGHTER = '#E8E8E8';
const LIGHTEST = '#FFFFFF';

const GRAY = '#F7F7F7';

const DANGER = '#F56C6C';
const DANGER_LIGHT = '#F78989';
const GOOD = '#008000';

const ERROR = '#F8D7DA';
const SUCCESS = '#D4EDDA';

const ORANGE = '#F3900E';
const ORANGEHALF = 'rgba(243, 144, 14, 0.5);';

const Z_INDEX_LOADING_OVERLAY = 10000;
const Z_INDEX_COMMAND_PALETTE = 9000;
const Z_INDEX_NOTIFICATION = 8000;
const Z_INDEX_TOOLTIP = 7000;
const Z_INDEX_MODAL = 6000;
const Z_INDEX_NAVBAR = 5000;
const Z_INDEX_INPUTS = 4000;
const Z_INDEX_DEFAULT = 1;

const SPACING = {
  1: '4px',
  2: '8px',
  3: '12px',
  4: '16px',
  5: '24px',
  6: '32px',
  7: '40px',
  8: '48px',
  9: '64px',
};

const theme = {
  // --------------------
  // General
  // --------------------
  // See /STYLING-DOCUMENTATION.md for more information
  // https://github.com/onomondo/onomondo-app/blob/master/STYLING-DOCUMENTATION.md
  // --------------------

  color: {
    ...colors.version2,
    ...colors,
  },

  // --------------------
  // Spacing
  // 2024 -> 🤷
  // --------------------
  // https://www.figma.com/file/Mmqat1JtfdQjtv3Lu2Dx76
  // --------------------
  spacing: SPACING,

  // --------------------
  // Radius
  // 2024 -> 🤷
  // --------------------
  // https://www.figma.com/design/edp8Jm4u5954IDSQJ5utgn/ODS---Components?node-id=5723-913763&t=0Plf1sXKhVZhRMgI-4
  // --------------------

  radius: {
    1: '3px',
    2: '4px',
    3: '6px',
    4: '8px',
    5: '12px',
    6: '16px',
    full: '9999px',
  },

  // --------------------
  // Z indexes
  // 2023 -> 🤷
  // --------------------
  // See /STYLING-DOCUMENTATION.md for more information on z-index
  // https://github.com/onomondo/onomondo-app/blob/master/STYLING-DOCUMENTATION.md#z-index
  // --------------------
  zIndex: {
    loadingOverlay: Z_INDEX_LOADING_OVERLAY,
    commandPalette: Z_INDEX_COMMAND_PALETTE,
    notification: Z_INDEX_NOTIFICATION,
    tooltip: Z_INDEX_TOOLTIP,
    modal: Z_INDEX_MODAL,
    navbar: Z_INDEX_NAVBAR,
    input: Z_INDEX_INPUTS,
    default: Z_INDEX_DEFAULT,
  },

  // --------------------
  // Colors
  // 👶 -> 2023
  // --------------------
  darkest: DARKEST,
  darker: DARKER,
  dark: DARK,
  darkMedium: DARKMEDIUM,
  copperDark: COPPER_DARK,
  copperDarkest: COPPER_DARKEST,
  copper: COPPER,
  copperLight: COPPER_LIGHT,
  copperLightest: COPPER_LIGHTEST,
  lightMedium: LIGHTMEDIUM,
  light: LIGHT,
  lighter: LIGHTER,
  lightest: LIGHTEST,
  danger: DANGER,
  dangerLight: DANGER_LIGHT,
  error: ERROR,
  success: SUCCESS,
  orange: ORANGE,
  orangeHalf: ORANGEHALF,
  gray: GRAY,

  bgColor: {
    main: LIGHTEST,
    muted: LIGHTER,
    secondary: DARKER,
    secondaryMuted: DARKMEDIUM,
    danger: DANGER,
    dangerLight: DANGER_LIGHT,
    good: GOOD,
  },

  font: {
    main: DARKEST,
    muted: DARKMEDIUM,
    secondary: LIGHTEST,
    code: "Menlo, 'Courier New', Courier, monospace",
  },

  link: {
    color: DARK,
    visited: DARKEST,
    active: DARK,
    hover: DARKEST,
  },
};

export default theme;
