import React, { useEffect, createContext, useState, useCallback } from 'react';
import io from 'socket.io-client';
import { fromApi as networkLogParser } from '~/lib/data-parsers/network-logs';
import { fromApi as signallingLogParser } from '~/lib/data-parsers/signalling-logs';
import { useAuth } from '@clerk/clerk-react';
import useAuthentication from '~/hooks/use-authentication';
import * as Sentry from '@sentry/browser';

const MonitorContext = createContext();
const socket = io(process.env.API_URL, {
  path: '/monitor',
  autoConnect: false,
  withCredentials: true,
});
const packetListeners = {}; // {simId -> [listener1, ...]}
const simConnectionListeners = {}; // {simId -> [listener1, ...]}
const simNetworkLogsListeners = {}; // {simId -> [listener1, ...]}
const simSignallingLogsListeners = {}; // {simId -> [listener1, ...]}
const simSmsListeners = {}; // {simId -> [listener1, ...]}
const organizationConnectionsListeners = {}; // {organizationId/0 -> [listener1, ...]}
let authToken;
let organizationId;
let getTokenForAuth = async () => null;

socket.on('error', err => console.error(`[Monitor] Error: ${err}`));
socket.on('disconnect', reason => {
  const didClientDisconnect = reason === 'io client disconnect';
  console.log(`[Monitor] Connection closed${didClientDisconnect ? '' : ', trying to reconnect..'}`);
  // if the connection is disconnected we call getTokenForAuth to change clerkToken state
  // and in turn that will update authToken in the useEffect below
  getTokenForAuth();
});
socket.on('connect', () => {
  console.log('[Monitor] Connection established');

  const authentication = {
    authToken,
    organizationId,
  };

  if (authToken) socket.emit('authenticate', authentication);
});

socket.on('authenticated', () => {
  console.log('[Monitor] Authenticated');
  Object.keys(packetListeners).forEach(simId => socket.emit('subscribe:packets', simId));
  Object.keys(simConnectionListeners).forEach(simId => socket.emit('subscribe:connections:sim', simId));
  Object.keys(simNetworkLogsListeners).forEach(simId => socket.emit('subscribe:network-logs:sim', simId));
  Object.keys(simSignallingLogsListeners).forEach(simId => socket.emit('subscribe:signalling-logs:sim', simId));
  Object.keys(simSmsListeners).forEach(simId => socket.emit('subscribe:sms:sim', simId));
  Object.keys(organizationConnectionsListeners).forEach(organizationId => {
    if (!organizationId) {
      socket.emit('subscribe:connections:organization');
    } else {
      socket.emit('subscribe:connections:organization', Number(organizationId));
    }
  });
});

socket.on('subscribed:packets', ({ simId, ip }) => {
  console.log(`[Monitor] Subscribed to packets. SIM id=${simId}. ip=${ip}`);

  const isListening = packetListeners[simId] && packetListeners[simId].length > 0;
  if (!isListening) return;

  packetListeners[simId].forEach(listener => listener.onSubscribed({ simId, ip }));
});

socket.on('subscribed:connections:sim', ({ simId, connection }) => {
  console.log(`[Monitor] Subscribed to connections:sim. SIM id=${simId}. connection=${JSON.stringify(connection)}`);

  const isListening = simConnectionListeners[simId] && simConnectionListeners[simId].length > 0;
  if (!isListening) return;

  simConnectionListeners[simId].forEach(listener => listener.onSubscribed(mapConnection(connection)));
});

socket.on('subscribed:connections:organization', ({ organizationId = 0, connections }) => {
  console.log(
    `[Monitor] Subscribed to connections:organization${organizationId ? `:${organizationId}` : ''}. connections=${
      connections.length
    } bytes=~${JSON.stringify(connections).length}`,
  );

  const isListening =
    organizationConnectionsListeners[organizationId] && organizationConnectionsListeners[organizationId].length > 0;
  if (!isListening) return;

  organizationConnectionsListeners[organizationId].forEach(listener =>
    listener.onSubscribed({
      organizationId,
      connections: connections.map(mapConnection),
    }),
  );
});

socket.on('subscribed:network-logs:sim', ({ simId }) => {
  console.log(`[Monitor] Subscribed to network-logs:sim. SIM id=${simId}.`);

  const isListening = simNetworkLogsListeners[simId] && simNetworkLogsListeners[simId].length > 0;
  if (!isListening) return;

  simNetworkLogsListeners[simId].forEach(listener => listener.onSubscribed(simId));
});

socket.on('subscribed:signalling-logs:sim', ({ simId }) => {
  console.log(`[Monitor] Subscribed to signalling-logs:sim. SIM id=${simId}.`);

  const isListening = simSignallingLogsListeners[simId] && simSignallingLogsListeners[simId].length > 0;
  if (!isListening) return;

  simSignallingLogsListeners[simId].forEach(listener => listener.onSubscribed(simId));
});

socket.on('subscribed:sms:sim', ({ simId }) => {
  console.log(`[Monitor] Subscribed to sms:sim. SIM id=${simId}.`);

  const isListening = simSmsListeners[simId] && simSmsListeners[simId].length > 0;
  if (!isListening) return;

  simSmsListeners[simId].forEach(listener => listener.onSubscribed(simId));
});

socket.on('packets', ({ simId, packet }) => {
  const isListening = packetListeners[simId] && packetListeners[simId].length > 0;
  if (!isListening) return;

  packetListeners[simId].forEach(listener => listener.onPacket(packet));
});

socket.on('connections:sim', ({ simId, connection }) => {
  const isListening = simConnectionListeners[simId] && simConnectionListeners[simId].length > 0;
  if (!isListening) return;

  simConnectionListeners[simId].forEach(listener => listener.onConnection(connection));
});

socket.on('connections:organization', ({ organizationId = 0, connections }) => {
  const isListening =
    organizationConnectionsListeners[organizationId] && organizationConnectionsListeners[organizationId].length > 0;
  if (!isListening) return;

  organizationConnectionsListeners[organizationId].forEach(listener =>
    listener.onConnections({ organizationId, connections }),
  );
});

socket.on('network-logs:sim', ({ simId, networkLog }) => {
  const isListening = simNetworkLogsListeners[simId] && simNetworkLogsListeners[simId].length > 0;
  if (!isListening) return;

  simNetworkLogsListeners[simId].forEach(listener => listener.onNetworkLogs(networkLogParser(networkLog)));
});

socket.on('signalling-logs:sim', ({ simId, signallingLog }) => {
  const isListening = simSignallingLogsListeners[simId] && simSignallingLogsListeners[simId].length > 0;
  if (!isListening) return;

  simSignallingLogsListeners[simId].forEach(listener => listener.onSignallingLogs(signallingLogParser(signallingLog)));
});

socket.on('sms:sim', ({ simId, sms }) => {
  const isListening = simSmsListeners[simId] && simSmsListeners[simId].length > 0;
  if (!isListening) return;

  simSmsListeners[simId].forEach(listener => listener.onSms(sms));
});

function mapConnection(connection) {
  const { id: simId, online_at: onlineAt, location_area_code: locationAreaCode, cell_id: cellId, ...rest } = connection;
  return {
    simId,
    onlineAt,
    locationAreaCode,
    cellId,
    ...rest,
  };
}

function subscribeSms({ simId, onSubscribed, onSms }) {
  console.log(`[Monitor] subscribeSms simId=${simId}`);

  simSmsListeners[simId] = simSmsListeners[simId] || [];
  simSmsListeners[simId].push({ onSubscribed, onSms });

  const isFirstListener = simSmsListeners[simId].length === 1;
  if (isFirstListener) socket.emit('subscribe:sms:sim', simId);
}

function unsubscribeSms({ simId }) {
  console.log(`[Monitor] unsubscribeSms simId=${simId}`);

  const isListening = simSmsListeners[simId] && simSmsListeners[simId].length > 0;
  if (!isListening) return;

  delete simSmsListeners[simId];
  socket.emit('unsubscribe:sms:sim', simId);
}

function subscribeNetworkLogs({ simId, onSubscribed, onNetworkLogs }) {
  console.log(`[Monitor] subscribeNetworkLogs simId=${simId}`);

  simNetworkLogsListeners[simId] = simNetworkLogsListeners[simId] || [];
  simNetworkLogsListeners[simId].push({ onSubscribed, onNetworkLogs });

  const isFirstListener = simNetworkLogsListeners[simId].length === 1;
  if (isFirstListener) socket.emit('subscribe:network-logs:sim', simId);
}

function unsubscribeNetworkLogs({ simId }) {
  console.log(`[Monitor] unsubscribeNetworkLogs simId=${simId}`);

  const isListening = simNetworkLogsListeners[simId] && simNetworkLogsListeners[simId].length > 0;
  if (!isListening) return;

  delete simNetworkLogsListeners[simId];
  socket.emit('unsubscribe:network-logs:sim', simId);
}

function subscribeSignallingLogs({ simId, onSubscribed, onSignallingLogs }) {
  console.log(`[Monitor] subscribeSignallingLogs simId=${simId}`);

  simSignallingLogsListeners[simId] = simSignallingLogsListeners[simId] || [];
  simSignallingLogsListeners[simId].push({ onSubscribed, onSignallingLogs });

  const isFirstListener = simSignallingLogsListeners[simId].length === 1;
  if (isFirstListener) socket.emit('subscribe:signalling-logs:sim', simId);
}

function unsubscribeSignallingLogs({ simId }) {
  console.log(`[Monitor] unsubscribeSignallingLogs simId=${simId}`);

  const isListening = simSignallingLogsListeners[simId] && simSignallingLogsListeners[simId].length > 0;
  if (!isListening) return;

  delete simSignallingLogsListeners[simId];
  socket.emit('unsubscribe:signalling-logs:sim', simId);
}

function subscribePackets({ simId, onSubscribed, onPacket }) {
  console.log(`[Monitor] subscribePackets simId=${simId}`);

  packetListeners[simId] = packetListeners[simId] || [];
  packetListeners[simId].push({ onSubscribed, onPacket });

  const isFirstListener = packetListeners[simId].length === 1;
  if (isFirstListener) socket.emit('subscribe:packets', simId);
}

function unsubscribePackets({ simId }) {
  console.log(`[Monitor] unsubscribePackets simId=${simId}`);

  const isListening = packetListeners[simId] && packetListeners[simId].length > 0;
  if (!isListening) return;

  delete packetListeners[simId];
  socket.emit('unsubscribe:packets', simId);
}

function subscribeSimConnection({ simId, onSubscribed, onConnection }) {
  console.log(`[Monitor] subscribeSimConnection simId=${simId}`);
  simConnectionListeners[simId] = simConnectionListeners[simId] || [];
  simConnectionListeners[simId].push({ onSubscribed, onConnection });

  const isFirstListener = simConnectionListeners[simId].length === 1;
  if (isFirstListener) socket.emit('subscribe:connections:sim', simId);
}

function unsubscribeSimConnection({ simId }) {
  console.log(`[Monitor] unsubscribeSimConnection simId=${simId}`);

  const isListening = simConnectionListeners[simId] && simConnectionListeners[simId].length > 0;
  if (!isListening) return;

  delete simConnectionListeners[simId];
  socket.emit('unsubscribe:connections:sim', simId);
}

function subscribeOrganizationConnections({ organizationId = 0, onSubscribed, onConnections }) {
  console.log(`[Monitor] subscribeOrganizationConnections organizationId=${organizationId}`);

  organizationConnectionsListeners[organizationId] = organizationConnectionsListeners[organizationId] || [];
  organizationConnectionsListeners[organizationId].push({ onSubscribed, onConnections });

  const isFirstListener = organizationConnectionsListeners[organizationId].length === 1;
  if (isFirstListener && !organizationId) socket.emit('subscribe:connections:organization');
  if (isFirstListener && organizationId) socket.emit('subscribe:connections:organization', organizationId);
}

function unsubscribeOrganizationConnections({ organizationId = 0 }) {
  console.log(`[Monitor] unsubscribeOrganizationConnections organizationId=${organizationId}`);

  const isListening =
    organizationConnectionsListeners[organizationId] && organizationConnectionsListeners[organizationId].length > 0;
  if (!isListening) return;

  delete organizationConnectionsListeners[organizationId];
  if (organizationId) {
    socket.emit('unsubscribe:connections:organization', organizationId);
  } else {
    socket.emit('unsubscribe:connections:organization');
  }
}

function MonitorProvider(props) {
  const { getToken } = useAuth();
  const [clerkToken, setClerkToken] = useState(null);
  const { usersCurrentOrganizationId } = useAuthentication();

  getTokenForAuth = useCallback(() => {
    getToken({ template: process.env.CLERK_DEFAULT_TOKEN_TEMPLATE })
      .then(token => {
        setClerkToken(token);
      })
      .catch(error => {
        Sentry.withScope(scope => {
          scope.setExtra('usersCurrentOrganizationId', usersCurrentOrganizationId);
          Sentry.captureException(error);
        });
        console.error('Error fetching token:', error);
      });
  }, [getToken, usersCurrentOrganizationId]);

  useEffect(() => {
    getTokenForAuth();
  }, [getToken]);

  useEffect(() => {
    authToken = clerkToken;
    organizationId = usersCurrentOrganizationId;
    if (clerkToken) socket.connect();

    return () => socket.disconnect();
  }, [clerkToken, usersCurrentOrganizationId]);

  return (
    <MonitorContext.Provider
      value={{
        socket,
        subscribePackets,
        unsubscribePackets,
        subscribeSimConnection,
        unsubscribeSimConnection,
        subscribeOrganizationConnections,
        unsubscribeOrganizationConnections,
        subscribeNetworkLogs,
        unsubscribeNetworkLogs,
        subscribeSignallingLogs,
        unsubscribeSignallingLogs,
        subscribeSms,
        unsubscribeSms,
      }}
      {...props}
    />
  );
}

export { MonitorProvider, MonitorContext };
