import React, { useEffect } from 'react';
import useTrackPageView from '~/hooks/use-track-page-view';
import useAuthentication from '~/hooks/use-authentication';
import Wrapper from '~/components/Wrapper';
import Loader from '~/components/Loader';
import { useHistory } from 'react-router-dom';

function Logout() {
  useTrackPageView('Log out');
  const { logout, isLoggedIn, isLoading } = useAuthentication();
  const history = useHistory();

  useEffect(() => {
    logout();
  }, [logout]);

  useEffect(() => {
    if (!isLoading && !isLoggedIn) history.replace('/login');
  }, [isLoggedIn, history, isLoading]);

  return (
    <Wrapper center>
      <Loader />
      <p>Logging you out</p>
    </Wrapper>
  );
}

export default Logout;
