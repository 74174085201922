import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthenticationProvider } from '~/context/authentication';
import { NotificationProvider } from '~/context/notification';
import { MonitorProvider } from '~/context/monitor';
import ErrorBoundary from '~/containers/ErrorBoundary';
import NotificationCenter from '~/containers/NotificationCenter';
import Routes from './Routes';
import { AnnouncementProvider } from '~/context/announcement';
import { ClerkProvider } from '@clerk/clerk-react';

function RoutesContainer() {
  return (
    <Router>
      <NotificationProvider>
        <ClerkProvider publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY}>
          <AuthenticationProvider>
            <AnnouncementProvider>
              <MonitorProvider>
                <ErrorBoundary>
                  <Routes />
                </ErrorBoundary>
              </MonitorProvider>
            </AnnouncementProvider>
          </AuthenticationProvider>
        </ClerkProvider>
        <NotificationCenter />
      </NotificationProvider>
    </Router>
  );
}

export default RoutesContainer;
