import React from 'react';
import useTrackPageView from '~/hooks/use-track-page-view';
import useAuthentication from '~/hooks/use-authentication';
import { Wrapper, Container, Logo } from '~/components/LoginElements';
import { SignIn, SignedIn, SignedOut } from '@clerk/clerk-react';
import { ChooseOrganization, RequestAccess } from '~/components/LoginActionElements';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { DEFAULT_PRIVATE_PATH } from '~/containers/Routes/Routes';

export const CLERK_SIGN_IN_VARIABLES = {
  spacingUnit: '12px',
  borderRadius: '4px',
  fontWeight: { bold: 400 },
};

function Login() {
  const theme = useTheme();
  useTrackPageView('Log In');
  const { isLoading, isLoggedIn, usersCurrentOrganizationId, usersOrganizations } = useAuthentication();
  const history = useHistory();

  if (isLoading) {
    return null;
  }

  if (isLoggedIn && usersCurrentOrganizationId) {
    const redirect = localStorage.getItem('redirect');
    if (!redirect) return history.replace(DEFAULT_PRIVATE_PATH);

    history.replace(redirect);
    // The double render React does, causes the redirect to be removed too soon
    return setTimeout(() => {
      localStorage.removeItem('redirect');
    }, 1000);
  }

  return (
    <Wrapper>
      <SignedOut>
        <Container>
          <Logo src={require('~/assets/graphics/onomondo-logo-type-negative.svg')} />
          <SignIn
            signUpUrl={process.env.ONOMONDO_WEBSITE_SIGN_UP_LINK}
            appearance={{
              variables: {
                ...CLERK_SIGN_IN_VARIABLES,
                colorPrimary: theme.darkest,
              },
            }}
          />
        </Container>
      </SignedOut>
      <SignedIn>{usersOrganizations.length > 0 ? <ChooseOrganization /> : <RequestAccess />}</SignedIn>
    </Wrapper>
  );
}

export default Login;
