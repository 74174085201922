import React, { useEffect, useState } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuthentication from '~/hooks/use-authentication';
import { DEFAULT_PRIVATE_PATH, DEFAULT_PUBLIC_PATH } from '~/containers/Routes/Routes';

function PrivateRoute({ children, requiredRoles = [], ...rest }) {
  const {
    userType,
    accessLevel,
    isLoggedIn,
    isLoading: authIsLoading,
    usersCurrentOrganizationId,
  } = useAuthentication();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (authIsLoading) return;
    if (!isLoggedIn || !usersCurrentOrganizationId) {
      setHasAccess(false);
      setLoading(false);
      return;
    }
    const areRolesRequired = requiredRoles.length !== 0;
    setHasAccess(!areRolesRequired || requiredRoles.includes(userType) || requiredRoles.includes(accessLevel));
    setLoading(false);
  }, [accessLevel, usersCurrentOrganizationId, authIsLoading, isLoggedIn, requiredRoles, userType]);

  if (loading) {
    return null;
  }

  function RenderRedirect() {
    if (isLoggedIn && usersCurrentOrganizationId) {
      return <Redirect to={DEFAULT_PRIVATE_PATH} />;
    }

    // Store the redirect path in localStorage, for later use
    localStorage.setItem('redirect', location.pathname + location.search);
    return <Redirect to={DEFAULT_PUBLIC_PATH} />;
  }

  return <Route {...rest}>{hasAccess ? children : <RenderRedirect />}</Route>;
}

PrivateRoute.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  requiredRoles: PropTypes.arrayOf(PropTypes.string),
};

export default PrivateRoute;
