import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css, useTheme } from 'styled-components';
import Icon from '~/components/Icon';

const primary = css`
  color: ${props => props.theme.copperDark};
  &:hover {
    color: ${props => props.theme.copperDarkest};
  }
`;

const dark = css`
  color: ${props => props.theme.lighter};
  &:hover {
    color: ${props => props.theme.copperLightest};
  }
`;

const StyledLink = styled.a`
  font-size: 13px;
  text-decoration: none;
  display: inline-flex;
  max-width: fit-content;
  ${props => props.variant === 'primary' && primary}
  ${props => props.variant === 'dark' && dark}
`;

const StyledWrapper = styled.div`
  display: inline-flex;
  border-bottom: 1px solid ${props => props.bottomBorderColor};
`;

const StyledIcon = styled.div`
  width: auto;
  height: auto;
  display: inline-flex;
`;

const Spacing = styled.div`
  width: 5px;
  display: inline-flex;
`;

const Link = props => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);

  let iconColor = props.color;
  let iconHoveredColor = props.color;
  let bottomBorderColor = props.color;

  if (props.variant && props.variant === 'primary') {
    iconColor = theme.copperDark;
    iconHoveredColor = theme.copperDarkest;
    bottomBorderColor = theme.copperDark;
  }

  if (props.variant && props.variant === 'dark') {
    iconColor = theme.lighter;
    iconHoveredColor = theme.copperLightest;
    bottomBorderColor = theme.lighter;
  }

  return (
    <StyledLink
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      icon={props.icon}
      variant={props.variant}
      target={props.target}
      href={props.href}
      rel={props.rel}
      onClick={props.onClick}
    >
      <StyledWrapper bottomBorderColor={bottomBorderColor}>
        {props.children}
        {props.icon && <Spacing />}
        {props.icon && (
          <StyledIcon>
            <Icon
              name='external-link'
              width={12}
              height={12}
              isHovered={isHovered}
              color={iconColor}
              hoveredColor={iconHoveredColor}
            />
          </StyledIcon>
        )}
      </StyledWrapper>
    </StyledLink>
  );
};

Link.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  href: PropTypes.string.isRequired,
  target: PropTypes.string,
  rel: PropTypes.string,
  icon: PropTypes.bool,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

export default Link;
