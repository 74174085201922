import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import SiteLoading from '~/containers/SiteLoading';
import CheckAccess from '~/containers/CheckAccess';
import Navigation from '~/containers/Navigation';
import Login from '~/pages/Login';
import Logout from '~/pages/Logout';
import SignUp from '~/pages/SignUp';
import PrivateRoutes from './PrivateRoutes';
import CommandMenu from '~/components/CommandMenu';
import useAuthentication from '~/hooks/use-authentication';
import PrivateRoute from '~/components/PrivateRoute';
import { FeatureProvider } from '~/context/feature';

const SetPassword = lazy(() => import(/* webpackChunkName: "setpassword" */ '~/pages/SetPassword'));

export const DEFAULT_PUBLIC_PATH = '/login';
export const DEFAULT_PRIVATE_PATH = '/dashboard';

function Routes() {
  const { isLoggedIn, usersCurrentOrganizationId, isLoading } = useAuthentication();
  const history = useHistory();
  const location = useLocation();

  const [isLoginPage, setIsLoginPage] = useState(false);
  const [isLogoutPage, setIsLogoutPage] = useState(false);

  useEffect(() => {
    const path = location.pathname;
    setIsLoginPage(path === '/login');
    setIsLogoutPage(path === '/logout');
  }, [location.pathname]);

  if (isLoading) {
    return <SiteLoading />;
  }

  if (isLoggedIn && !usersCurrentOrganizationId && !isLoginPage && !isLogoutPage) {
    return history.push('/login');
  }

  return (
    <Suspense fallback={<SiteLoading />}>
      <Switch>
        <Route
          path='/'
          exact
        >
          {isLoggedIn && usersCurrentOrganizationId ? (
            <Redirect to={DEFAULT_PRIVATE_PATH} />
          ) : (
            <Redirect to={DEFAULT_PUBLIC_PATH} />
          )}
        </Route>

        <Route path='/sign-up'>
          <SignUp />
        </Route>

        <Route path='/login'>
          <Login />
        </Route>

        <Route path='/logout'>
          <Logout />
        </Route>

        <Route path='/reset'>
          <SetPassword />
        </Route>

        <PrivateRoute to='/'>
          <CheckAccess>
            <FeatureProvider>
              <CommandMenu />
              <Navigation />
              <PrivateRoutes />
            </FeatureProvider>
          </CheckAccess>
        </PrivateRoute>

        <Route path='*'>
          <Redirect to={DEFAULT_PUBLIC_PATH} />
        </Route>
      </Switch>
    </Suspense>
  );
}

export default Routes;
