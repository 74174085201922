import React, { useState, useCallback } from 'react';
import styled, { useTheme } from 'styled-components';
import { Logo, Form, Divider } from '~/components/LoginElements';
import { Text, Typography } from '~/components/Text';
import { ContinueBtn, SuccessMessage, MessageTop } from '~/pages/SignUp/components/Elements';
import Icon from '~/components/Icon';
import useAuthentication from '~/hooks/use-authentication';
import useNotification from '~/hooks/use-notification';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useUser } from '@clerk/clerk-react';
import mixpanel from 'mixpanel-browser';

const Wrapper = styled.div`
  display: flex;
  width: 344px;
  height: 607px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => props.theme.spacing[8]};
  align-self: stretch;
`;

const LocalForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${props => props.theme.spacing[7]};
  align-self: stretch;
  width: 100%;
  padding: 0;
`;

const LocalDivider = styled(Divider)`
  margin: 0;
`;

const CopyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => props.theme.spacing[2]};
  align-self: stretch;
`;

const GhostButton = styled(ContinueBtn)`
  width: fit-content;
  height: 32px;
  gap: ${props => props.theme.spacing[2]};
  background: none;
  &:hover {
    cursor: pointer;
    background: ${props => props.theme.color.version3.neutral.mainAlpha[3]};
  }
`;

const LogoutButtonWrapper = styled.div`
  position: absolute;
  top: ${props => props.theme.spacing[7]};
  right: ${props => props.theme.spacing[9]};
`;

const MessageWrapper = styled.div`
  width: 344px;
  height: 64px;
  margin-bottom: ${props => props.theme.spacing[5]};
`;

const FORM_PREFILLS = {
  how_did_you_hear_about_onomondo_: 'https://app.onomondo.com',
  message: 'I might have had an account with you before, could you help me get started again?',
};

const PARAMETER_TO_CLERK_MAPPING = {
  firstname: 'firstName',
  lastname: 'lastName',
  email: 'primaryEmailAddress',
  tel: 'primaryPhoneNumber',
};

function RequestAccess() {
  const { isRequestAccessAvailable, requestAccess } = useAuthentication();
  const [shouldShowDialog, setShouldShowDialog] = useState(false);
  const theme = useTheme();
  const history = useHistory();
  const { addNotification } = useNotification();
  const { user } = useUser();

  const onContactSales = useCallback(() => {
    const params = new URLSearchParams();

    for (const [parameterName, clerkName] of Object.entries(PARAMETER_TO_CLERK_MAPPING)) {
      params.append(parameterName, user?.[clerkName]);
    }

    for (const [parameterName, prefill] of Object.entries(FORM_PREFILLS)) {
      params.append(parameterName, prefill);
    }

    const emailString = user?.primaryEmailAddress?.toString();
    mixpanel.track('Existing company domain user requested sales contact', {
      Email: emailString,
      'Existing company domain': emailString?.split('@')[1],
    });
    window.open(`${process.env.CONTACT_SALES_URL}?${params.toString()}`, '_blank').focus();
  }, [user]);

  return (
    <>
      <LogoutButtonWrapper>
        <GhostButton
          onClick={() => {
            history.push('/logout');
          }}
        >
          <Icon
            height={16}
            width={16}
            inline
            name='log-out-01'
            color={theme.color.version3.neutral.main[11]}
          />
          <Text
            size={2}
            weight='medium'
            color={theme.color.version3.neutral.main[11]}
          >
            Logout
          </Text>
        </GhostButton>
      </LogoutButtonWrapper>
      <MessageWrapper>
        {shouldShowDialog && (
          <SuccessMessage>
            <MessageTop>
              <Icon
                height={16}
                width={16}
                inline
                name='check-circle'
                color={theme.color.version3.success.mainAlpha[11]}
              />
              <div>
                <Typography color={theme.color.version3.success.mainAlpha[11]}>
                  A request has been sent, we will notify you when it’s been approved.
                </Typography>
              </div>
            </MessageTop>
          </SuccessMessage>
        )}
      </MessageWrapper>
      <Wrapper>
        <TopContainer>
          <Logo src={require('~/assets/graphics/onomondo-logo-type-negative.svg')} />
          <LocalForm as='div'>
            <CopyWrapper>
              <Text
                size={6}
                weight='medium'
                color={theme.color.version3.neutral.main[12]}
                align='center'
              >
                Your company already has an account
              </Text>
              <Text
                size={2}
                align='center'
                weight='regular'
                color={theme.color.version3.neutral.main[11]}
              >
                {`An account with your company domain already exists, or you're not assigned to an organization yet.
                Request access or contact our sales team.`}
              </Text>
            </CopyWrapper>
            <ContinueBtn
              onClick={async () => {
                if (isRequestAccessAvailable) {
                  try {
                    await requestAccess();
                    const emailString = user?.primaryEmailAddress?.toString();
                    mixpanel.track('Existing company domain user requested access from owner', {
                      Email: emailString,
                      'Existing company domain': emailString?.split('@')[1],
                    });
                    setShouldShowDialog(true);
                  } catch (e) {
                    console.error(e);
                    addNotification({ message: 'Something went wrong. Please try again later.', type: 'error' });
                  }
                } else {
                  onContactSales();
                }
              }}
            >
              <Text
                size={3}
                weight='medium'
                color={theme.color.version3.neutral.main[1]}
              >
                {isRequestAccessAvailable ? 'Request Access' : 'Contact sales'}
              </Text>
            </ContinueBtn>
            {isRequestAccessAvailable && (
              <>
                <LocalDivider>
                  <Text
                    size={1}
                    weight='regular'
                    color={theme.color.version3.neutral.main[11]}
                  >
                    or
                  </Text>
                </LocalDivider>
                <GhostButton onClick={onContactSales}>
                  <Text
                    size={2}
                    weight='medium'
                    color={theme.color.version3.neutral.main[11]}
                  >
                    Contact sales
                  </Text>
                </GhostButton>
              </>
            )}
          </LocalForm>
        </TopContainer>
        {isRequestAccessAvailable && (
          <div>
            <Text
              size={1}
              align='center'
              weight='regular'
              color={theme.color.version3.neutral.main[11]}
            >
              By requesting access, you consent to sharing your email information with the account owner.
            </Text>
          </div>
        )}
      </Wrapper>
    </>
  );
}

export default RequestAccess;
